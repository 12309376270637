import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import Provider from 'modules/wd-api-request-handler'
import Handle from "modules/wd-transit/handle";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const API_BASE_URL = "https://api2.webdoko.com/"
// const API_BASE_URL = "http://localhost:8000/"

export const DOWNLOAD_BASE_PATH = "https://np.webdoko.com/d/"
// export const DOWNLOAD_BASE_PATH = "http://localhost:3000/d/"

export const SHARE_BASE_PATH = "https://np.webdoko.com/s/"
// export const SHARE_BASE_PATH = "http://localhost:3000/s/"

root.render(
  <React.StrictMode>
    <Provider baseURL={API_BASE_URL}>
      <App />
      <Handle baseURL={API_BASE_URL} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
