import {useEffect, Suspense, lazy} from 'react'
import { Loader } from 'semantic-ui-react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import './App.css';

const LandingPage = lazy(() => import('./pages/drive/landingpage'))
const DownloadPage = lazy(() => import('./pages/drive/downloadfile'))

const PageNotFound = lazy(() => import('./pages/common/notfound'))

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  return (
      <Router>
        <Suspense fallback={<Loader active inline style={{margin: 10}}/>}>
          <ScrollToTop />
          <Routes>
             <Route exact path='/' element={<LandingPage />}></Route>
             <Route exact path='/s/:type/:hash' element={<LandingPage />}></Route>
             <Route exact path='/s/:type/:parent__id/:hash' element={<LandingPage />}></Route>
             <Route exact path='/d/:type/:hash' element={<DownloadPage />}></Route>
             <Route exact path='/d/:type/:parent__id/:hash' element={<DownloadPage />}></Route>
             <Route exact path='*' element={<PageNotFound/>}></Route>
          </Routes>
        </Suspense>
      </Router>
  );
}

export default App;
